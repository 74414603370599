import {Controller} from '@hotwired/stimulus';
import {push} from 'src/Push';

export default class LeipController extends Controller {
    static targets = [
        'title',
        'noEntry',
        'hasEntry',
        'issuesFrame',
        'rulesFrame',
        'historyFrame',
        'noHistory',
        'loadingSpinnerTemplate',
        'loadingHistoryTemplate',
        'rulesTestFailTemplate',
    ];

    static outlets = ['scheduler--drawer'];

    static values = {
        serviceId: String,
        serviceTimeZone: {type: String, default: 'UTC'},
        resolutionUrl: String,
        displayRulesUrl: String,
    };

    userClosed = false;
    entryId = null;
    entryDt = null;
    entryType = null; // 'log_entry' or 'position'
    audioId = null;
    audioSource = null; // drawer / log_entry
    audioHistoryUrl = null;
    logEntryIssuesUrl = null;
    loadedContent = false; // for knowing if content has already been loaded when toggling the panel open

    static resolutionRequestId;

    connect() {
        // Store a stable callback to be removed when this class inst is disconnected
        this.pushCallback = this._handlePushMessage.bind(this);
        push.on('message', this.pushCallback);
    }

    disconnect() {
        super.disconnect();
        push.off('message', this.pushCallback);
    }

    toggled(e) {
        if (this.isOpen) {
            if (!this.loadedContent && this.entryDt) {
                this._loadContent();
            }
        } else {
            this.userClosed = true;
        }
    }

    /**
     * 'audio' is an object containing id, title (and artist)
     *  It could be either a selected log entry
     *  or if a log entry is selected AND a drawer item, it is the audio of the drawer item
     *  If ONLY a drawer item is selected (no log entry), audio will be null
     */
    update({logEntry = null, audio = null}) {
        if (logEntry !== null) {
            // We show something if a log entry is selected
            // Even if it has no audio, it might still have issues
            const displayDT = this._formatDateTimeForDisplay(logEntry.dt);
            this.entryId = logEntry.id;
            this.entryDt = logEntry.dt;
            this.entryType = logEntry.type;
            this.titleTarget.innerText =
                audio !== null
                    ? `for ${audio.title} by ${audio.artist} on ${displayDT}`
                    : `for ${displayDT}`;
            this.audioId = audio?.id ?? null;
            this.audioHistoryUrl = audio?.historyUrl ?? null;
            this.audioSource = audio?.source ?? null;

            // An issues URL will only be passed if a drawer item is not selected, and is therefore for the log entry
            this.logEntryIssuesUrl = logEntry?.issuesUrl ?? null;

            if (!this.isOpen && !this.userClosed) {
                // LEIP is closed, but not because the user explicitly closed it
                //  so open it
                this.open();
            }
            if (this.isOpen) {
                // If it was just opened, or already open, load content for this audio
                this._loadContent();
            } else {
                // It remains closed, so clear it of its old content,
                //  so that when it's next opened, the new content will be loaded
                this._clearContent();
            }
        } else {
            this.entryId = null;
            this.entryDt = null;
            this.entryType = null;
            this.audioId = null;
            this.titleTarget.innerText = '';
            this._clearContent();
        }
    }

    openIssues(e) {
        e.preventDefault();
        const url = e.currentTarget.getAttribute('href');
        // Switch the drawer to the "Issues" stack
        this.schedulerDrawerOutlet.openInStack('issues', url, true);
    }

    _loadContent() {
        this.loadedContent = true;

        this.noEntryTarget.classList.add('tone-u-hidden');
        this.hasEntryTarget.classList.remove('tone-u-hidden');

        if (this.logEntryIssuesUrl) {
            this._loadSrcInFrame(
                this.logEntryIssuesUrl,
                this.issuesFrameTarget,
                this.loadingSpinnerTemplateTarget,
            );
        } else {
            this.issuesFrameTarget.src = null;
            this.issuesFrameTarget.innerHTML = '';
        }

        this._requestRuleChecks();

        if (this.audioHistoryUrl) {
            const qs = new URLSearchParams({
                service_id: this.serviceIdValue,
                entry_dt: this.entryDt,
            });
            this._loadSrcInFrame(
                this.audioHistoryUrl + '?' + qs,
                this.historyFrameTarget,
                this.loadingHistoryTemplateTarget,
            );
            this.historyFrameTarget.classList.remove('tone-u-hidden');
            this.noHistoryTarget.classList.add('tone-u-hidden');
        } else {
            this.historyFrameTarget.classList.add('tone-u-hidden');
            this.noHistoryTarget.classList.remove('tone-u-hidden');
        }
    }

    _clearContent() {
        this.loadedContent = false;
        this.noEntryTarget.classList.remove('tone-u-hidden');
        this.hasEntryTarget.classList.add('tone-u-hidden');
        this.historyFrameTarget.src = null;
        this.historyFrameTarget.innerHTML = '';
        this.historyFrameTarget.classList.add('tone-u-hidden');
        this.noHistoryTarget.classList.remove('tone-u-hidden');
    }

    /**
     * This fires off a request to the resolution API to test the audio against the log entry
     * The response is received via WebSocket
     * We then pass the result on to a view via query string to render
     */
    _requestRuleChecks() {
        if (!this.audioId || !this.entryId) {
            this.rulesFrameTarget.innerHTML = '';
            return;
        }
        this._showLoadingInFrame(
            this.rulesFrameTarget,
            this.loadingSpinnerTemplateTarget,
        );

        const requestId = 'leip_' + Date.now();
        LeipController.resolutionRequestId = requestId;

        const payload = {
            audio_ids: [this.audioId],
            dt: this.entryDt,
            id: requestId,
        };
        if (this.entryId && this.entryType === 'log_entry') {
            payload.log_entry_id = this.entryId;
        }

        fetch(this.resolutionUrlValue, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });
    }

    /**
     * See comparison-controller for sample payloads
     */
    _handlePushMessage(data) {
        if (data?.type !== 'scheduler_issue_resolution') {
            // We're only interested in this type
            return;
        }
        console.log('WS received in LEIP', data);
        if (data?.id !== LeipController.resolutionRequestId) {
            //console.log('LEIP Resolution request ID mismatch', {
            //    expected: LeipController.resolutionRequestId,
            //    provided: data?.id,
            //});
            return;
        }
        if (data?.status === 'failed') {
            const clone =
                this.rulesTestFailTemplateTarget.content.cloneNode(
                    true,
                ).firstElementChild;
            this.rulesFrameTarget.replaceChildren(clone);
            return;
        }
        const {results} = data;
        const result = results[0];

        // Example test objects
        //const result2 = {
        //    "audio_id": this.audioId,
        //    "passes": true,
        //    "issues": [],
        //};

        //const result = {
        //    "audio_id": this.audioId,
        //    "passes": false,
        //    "issues": [
        //        {
        //            "type": "rule",
        //            "rule_id": 26,
        //        },
        //        {
        //            "type": "rule",
        //            "rule_id": 25,
        //        },
        //    ],
        //};

        if (result?.audio_id !== this.audioId) {
            // Audio ID mismatch, discard
            return;
        }

        const {passes, issues} = result;
        const ruleIds = issues
            .filter((issue) => issue.type === 'rule')
            .map((issue) => issue.rule_id);
        const params = new URLSearchParams({
            passes,
            ruleIds,
            audioSource: this.audioSource,
        });
        const qs = params.toString();

        this._loadSrcInFrame(
            this.displayRulesUrlValue + '?' + qs,
            this.rulesFrameTarget,
            this.loadingSpinnerTemplateTarget,
        );
    }

    _loadSrcInFrame(src, frame, loadingTarget) {
        // First insert 'loading' indicator
        this._showLoadingInFrame(frame, loadingTarget);
        frame.src = src;
    }

    _showLoadingInFrame(frame, loadingTarget) {
        const clone = loadingTarget.content.cloneNode(true).firstElementChild;
        frame.replaceChildren(clone);
    }

    _formatDateTimeForDisplay(dt) {
        const locale = window?.AP?.Session?.User?.language ?? 'en-US';
        const dtFormat = new Intl.DateTimeFormat(locale, {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
            timeZone: this.serviceTimeZoneValue,
        });
        const date = new Date(dt);
        return dtFormat.format(date);
    }

    open() {
        this.element.open = true;
    }

    // Only uncomment if this method is needed
    //close() {
    //    this.element.open = false;
    //}

    // Only uncomment if this method is needed
    //toggle() {
    //    this.element.open = !this.element.open;
    //}

    get isOpen() {
        return this.element.open;
    }
}
