import {Controller} from '@hotwired/stimulus';
import {push} from 'src/Push';

export default class extends Controller {
    static targets = ['progress', 'abortButton'];

    static values = {
        id: Number,
    };

    connect() {
        // Store a stable callback to be removed when this class inst is disconnected
        this.pushCallback = this._handlePushMessage.bind(this);
        push.on('message', this.pushCallback);
    }

    disconnect() {
        super.disconnect();
        push.off('message', this.pushCallback);
    }

    _handlePushMessage(data) {
        if (data?.type !== 'scheduler_scheduling_progress') {
            // We're only interested in this type here
            return;
        }
        if (data?.scheduleId !== this.idValue) {
            // Schedule ID mismatch
            return;
        }
        const progEl = this.progressTarget;
        if (data.position >= data.total) {
            // Once position reaches the total, make the progress bar indeterminate
            progEl.removeAttribute('value');
            progEl.removeAttribute('max');
            this.abortButtonTarget.disabled = true;
            //this.abortButtonTarget.classList.add('is-disabled');
        } else {
            progEl.setAttribute('value', data.position);
            progEl.setAttribute('max', data.total);
        }
    }
}
