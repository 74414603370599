import {Controller} from '@hotwired/stimulus';
import {renderStreamMessage} from '@hotwired/turbo';
import Modal from 'src/ui/loaders/ModalLoader';

export default class extends Controller {
    static targets = ['emptyPlaceholder', 'loadingTemplate'];

    static values = {
        createLogUrl: String,
        addItemUrl: String,
    };

    saveItemPosition(item) {
        // If the item is missing 'data-sortable-index' it's because it's been dragged in from the drawer
        //  which triggers the 'sort' event and therefore this function,
        //  but we don't need to save its position as that's handled by the 'add item' request
        const {sortableIndex, savePositionUrl} = item.dataset;
        if (!sortableIndex) {
            return;
        }

        if (savePositionUrl) {
            // Get adjacent indexes and IDs
            const adjacentProps = getAdjacentProps(item);

            fetch(savePositionUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(adjacentProps),
            })
                .then((response) => response.json())
                .then(
                    (data) => (item.dataset.sortableIndex = data.sortableIndex),
                );
        } else {
            this.createLog({
                setEntryIndex: {
                    index: item.dataset.sortableIndex,
                    ...getAdjacentProps(item),
                },
            });
        }
    }

    handleSort(e) {
        //console.log('log table sort', e);
        const sortEvent = e.detail.event;
        const {item} = sortEvent;
        this.saveItemPosition(item);
    }

    // Called via an event on the sortable element emitted by the generic sortable controller
    // e.g. data-action="sortable:moveUp->scheduler--sortable#move"
    move(e) {
        const target = e.detail.event.target;
        const item = target.closest('[data-sortable-index]');
        if (!item) return;

        this.saveItemPosition(item);
    }

    remove(e) {
        const target = e?.detail?.event?.target ?? e.target;
        const item = target.closest('[data-sortable-index]');
        if (!item) return;

        const {removeItemUrl} = item.dataset;
        if (removeItemUrl) {
            fetch(removeItemUrl, {
                method: 'DELETE',
            });
            item.remove();
            this.dispatch('remove');
        } else {
            this.createLog({
                removeEntry: {
                    index: item.dataset.sortableIndex,
                },
            });
        }
    }

    async handleDraggedIn(e) {
        //console.log('log table dragged in', e);
        const dragEvent = e.detail.event;
        const {item} = dragEvent;

        // Get properties of dragged in item
        const draggableType = item.dataset.draggableType; // e.g. 'audio'
        const draggableId = item.dataset.draggableId; // e.g. ID of audio item

        // Get adjacent indexes
        const adjacentProps = getAdjacentProps(item);

        // Replace dragged in item with temporary placeholder row
        const clone =
            this.loadingTemplateTarget.content.cloneNode(
                true,
            ).firstElementChild;
        const tempId = this.getTempId();
        clone.id = tempId;
        item.replaceWith(clone);

        // If there's an addItemUrl, a `log` exists for this hour:
        //  - Post to add the entry
        //  - Response is the row in a Turbo Stream
        // Else a `log` doesn't exist:
        //  - Post to create the Log AND add the entry
        //  - Response is the whole new Log table in a Turbo Stream
        const addEntry = {
            type: draggableType,
            id: draggableId,
            ...adjacentProps,
        };

        if (this.addItemUrlValue) {
            postJsonRenderStream(
                this.addItemUrlValue,
                {
                    ...addEntry,
                    streamTarget: tempId,
                },
                (status) => {
                    if (status === 201) {
                        this.dispatch('add');
                    }
                },
            );
        } else {
            this.createLog({addEntry});
        }
    }

    insertAfter(e) {
        e.preventDefault();
        const {currentTarget} = e;
        const insertUrl = currentTarget.getAttribute('href');

        const item = currentTarget.closest('[data-sortable-index]');
        if (!item) return;

        // Insert note/commercial after this item, so indexBefore will be this item
        const params = getAdjacentProps(item);
        const qs = new URLSearchParams({
            indexBefore: item?.dataset.sortableIndex,
        });
        if (params.indexAfter) {
            qs.append('indexAfter', params.indexAfter);
        }
        const url = insertUrl + '?' + qs;

        Modal.open({
            url,
        });
        return false;
    }

    duplicate(e) {
        e.preventDefault();
        const {currentTarget} = e;
        const item = currentTarget.closest('[data-sortable-index]');
        if (!item) return;

        const {duplicateItemUrl} = item.dataset;
        const adjacentProps = getAdjacentProps(item);
        if (duplicateItemUrl) {
            postJsonRenderStream(
                duplicateItemUrl,
                {
                    ...adjacentProps,
                },
                (status) => {
                    if (status === 201) {
                        this.dispatch('add');
                    }
                },
            );
        } else {
            this.createLog({
                duplicateEntry: {
                    index: item.dataset.sortableIndex,
                    ...adjacentProps,
                },
            });
        }
    }

    createLog(body) {
        postJsonRenderStream(this.createLogUrlValue, body);
    }

    // Generate a temporary ID for the 'loading' element
    //  so it can be targeted by the Turbo Stream to replace it with the real entry
    tempCount = 0;
    getTempId() {
        this.tempCount++;
        return 'temp_entry_' + new Date().getTime() + this.tempCount;
    }
}

function postJsonRenderStream(url, body, callback = null) {
    fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'text/vnd.turbo-stream.html',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (callback !== null) {
                callback(response.status);
            }
            return response.text();
        })
        .then((html) => renderStreamMessage(html));
}

function getAdjacentProps(item) {
    // Get nearest siblings which are sortable
    const prevItem = getPreviousSibling(item, '[data-sortable-index]');
    const nextItem = getNextSibling(item, '[data-sortable-index]');
    const indexBefore = prevItem?.dataset.sortableIndex ?? null;
    const indexAfter = nextItem?.dataset.sortableIndex ?? null;
    return {indexBefore, indexAfter};
}

// Getting siblings which match selectors
// https://gomakethings.com/finding-the-next-and-previous-sibling-elements-that-match-a-selector-with-vanilla-js/
function getNextSibling(elem, selector) {
    let sibling = elem.nextElementSibling;
    if (!selector) return sibling;
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling;
    }
    return null;
}

function getPreviousSibling(elem, selector) {
    let sibling = elem.previousElementSibling;
    if (!selector) return sibling;
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.previousElementSibling;
    }
    return null;
}
