import {Controller} from '@hotwired/stimulus';
import {push} from 'src/Push';

/**
 * Payload received from WebSocket:
 * {
 *   "id": "whatever is passed through",
 *   "type": "scheduler_issue_resolution",
 *   "results": [
 *     {
 *       "audio_id": 1,
 *       "passes": true,
 *       "issues: []
 *     },
 *     {
 *       "audio_id": 2,
 *       "passes": false,
 *       "issues": [
 *         {
 *           "type": "rule",
 *           "rule_id": 1,
 *           "breakable": true,
 *           "candidate_audio_id": 1
 *         },
 *         {
 *           "type": "rule",
 *           "rule_id": 2,
 *           "breakable": false,
 *           "candidate_audio_id": 1
 *         },
 *       ]
 *     }
 *   ]
 * }
 *
 * Payload received if something fundamentally goes wrong:
 * {
 *   "type": "scheduler_issue_resolution",
 *   "audio_ids": [
 *     1,
 *     2,
 *     3,
 *     4
 *   ],
 *   "status": "failed",
 *   "error": "internal",
 *   "error_stack": "XXX ERROR STACK STRING GOES HERE XXX"
 * }
 */
export default class ComparisonController extends Controller {
    static targets = [
        'logRowInput',
        'drawerRowInput',
        'audio',
        'spinnerIcon',
        'passIcon',
        'failUnbreakableIcon',
        'failBreakableIcon',
        'errorIcon',
    ];

    static outlets = ['scheduler--drawer', 'scheduler--leip'];

    static values = {
        logEntryId: String,
        logEntryDateTime: String,
        drawerRowId: String,
        resolutionUrl: String,
    };

    static classes = ['contextActive'];

    logEntryType = null;
    logEntryIssuesUrl = null;
    logEntryAudioMetadata = null;
    drawerRowAudioMetadata = null;

    // This is static so it carries across page loads (when controller is disconnected)
    static resolutionRequestId;

    connect() {
        // Store a stable callback to be removed when this class inst is disconnected
        this.pushCallback = this._handlePushMessage.bind(this);
        push.on('message', this.pushCallback);
    }

    disconnect() {
        super.disconnect();
        push.off('message', this.pushCallback);
    }

    // Called whenever air times are populated - on page load, on re-order, etc.
    // Look for a selected entry and call selectLogRow to update the date time we're comparing with
    handleCheckedLogRow() {
        const checkedInput = this.logRowInputTargets.find((el) => el.checked);
        if (checkedInput) {
            this.selectLogRow({target: checkedInput});
        }
    }

    // Called from 'change' event on log entry's radio button
    selectLogRow({target}) {
        // This is temporary until we support multi-selection for bulk actions
        if (target.checked) {
            enforceSingleChecked(this.logRowCheckedInputs, target);
        }
        // If this one is checked, and it's the only one...
        if (target.checked && this.logRowCheckedInputs.length === 1) {
            const logRow = target.closest('[data-date-time]');
            this.logEntryIdValue = logRow.id.replace(
                /log_entry_|position_/,
                '',
            );
            this.logEntryType = logRow.id.includes('position_')
                ? 'position'
                : 'log_entry';
            const metadataEl = logRow.querySelector(
                '[data-table-row-metadata]',
            );
            this.logEntryAudioMetadata = metadataEl
                ? JSON.parse(metadataEl.textContent)
                : null;
            this.logEntryDateTimeValue = logRow.dataset.dateTime;
            this.logEntryIssuesUrl = logRow.dataset.issuesUrl;
        } else {
            this.logEntryIdValue = '';
            this.logEntryAudioMetadata = null;
            this.logEntryDateTimeValue = '';
            this.logEntryIssuesUrl = null;
        }
    }

    logEntryIdValueChanged(val, prev) {
        this.element.classList.toggle(this.contextActiveClass, val !== '');
        if (val !== '') {
            // Reset 'tested' state on all audioTargets
            this.audioTargets.forEach((el) => {
                el.tested = false;
                this.replaceChildrenWithTemplate(el, this.spinnerIconTarget);
            });
            this.requestResolution();
        }
        this.sendDataToLeip();
    }

    // Handle if selected log entry row is removed
    logRowInputTargetDisconnected(el) {
        const logRow = el.closest('[data-date-time]');
        if (logRow) {
            const id = logRow.id.replace(/log_entry_|position_/, '');
            if (id === this.logEntryIdValue) {
                this.logEntryIdValue = '';
                this.logEntryDateTimeValue = '';
                this.logEntryType = null;
            }
        }
    }

    // Called from 'change' event on drawer item's radio button
    selectDrawerRow({target}) {
        // Only allow one row in the drawer to be selected
        if (target.checked) {
            enforceSingleChecked(this.drawerRowCheckedInputs, target);
            const rowId = target.value;
            this.drawerRowIdValue = rowId;
            const drawerRow = target.closest('.sch-c-table-row');
            const metadataEl = drawerRow.querySelector(
                '[data-table-row-metadata]',
            );
            this.drawerRowAudioMetadata = metadataEl
                ? JSON.parse(metadataEl.textContent)
                : null;
        } else {
            this.drawerRowIdValue = '';
            this.drawerRowAudioMetadata = null;
        }
    }

    drawerRowIdValueChanged(val, prev) {
        this.sendDataToLeip();
    }

    sendDataToLeip() {
        const payload = {};
        if (this.logEntryIdValue !== '') {
            payload.logEntry = {
                id: this.logEntryIdValue,
                dt: this.logEntryDateTimeValue,
                type: this.logEntryType,
            };
            if (this.drawerRowIdValue !== '') {
                payload.audio = this.drawerRowAudioMetadata ?? null;
                if (payload.audio) {
                    payload.audio.source = 'drawer';
                }
            } else {
                payload.audio = this.logEntryAudioMetadata ?? null;
                if (payload.audio) {
                    payload.audio.source = 'log_entry';
                }
                payload.logEntry.issuesUrl = this.logEntryIssuesUrl ?? null;
            }
        }
        this.schedulerLeipOutlet.update(payload);
    }

    audioTargetConnected() {
        this.requestResolution();
    }

    openIssueForLogEntry(e) {
        // Get this log entry's input (radio button) and select it
        const labelEl = e.currentTarget.closest('label');
        const inputEl = this.logRowInputTargets.find((input) =>
            labelEl.contains(input),
        );
        inputEl.checked = true;
        this.selectLogRow({target: inputEl});

        // Switch the drawer to the "Issues" stack
        this.schedulerDrawerOutlet.openInStack('issues', e.params.url, true);
    }

    batchRequestTimeout;

    requestResolution() {
        if (!this.hasAudioTarget || this.logEntryDateTimeValue === '') {
            return;
        }
        clearTimeout(this.batchRequestTimeout);
        this.batchRequestTimeout = setTimeout(() => {
            const audioIds = this.audioTargets
                .filter((el) => !el?.tested)
                .map((el) => el.dataset.audioId);
            if (audioIds.length === 0) {
                return;
            }

            this.audioTargets.forEach((el) => (el.tested = true));

            const requestId = 'comparison_' + Date.now();
            ComparisonController.resolutionRequestId = requestId;

            fetch(this.resolutionUrlValue, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    audio_ids: audioIds,
                    dt: this.logEntryDateTimeValue,
                    id: requestId,
                }),
            });
        }, 50);
    }

    _handlePushMessage(data) {
        if (data?.type !== 'scheduler_issue_resolution') {
            // We're only interested in this type
            return;
        }
        console.log('WS received in Comparison', data);
        if (data?.id !== ComparisonController.resolutionRequestId) {
            //console.log('Comparison Resolution request ID mismatch', {
            //    expected: ComparisonController.resolutionRequestId,
            //    provided: data?.id,
            //});
            return;
        }
        if (data?.status === 'failed') {
            const {audio_ids} = data;
            audio_ids.forEach((audioId) =>
                this.displayIconForAudioId(audioId, this.errorIconTarget),
            );
            return;
        }
        const {results} = data;
        results.forEach((result) => {
            const {audio_id} = result;
            let iconTarget;
            if (result.issues.length === 0) {
                iconTarget = this.passIconTarget;
            } else {
                // Are any of the issues unbreakable? (have breakable = false)
                const isUnbreakable = result.issues
                    .filter((issue) => issue.type === 'rule')
                    .some((issue) => !issue.breakable);
                iconTarget = isUnbreakable
                    ? this.failUnbreakableIconTarget
                    : this.failBreakableIconTarget;
            }
            this.displayIconForAudioId(audio_id, iconTarget);
        });
    }

    displayIconForAudioId(audioId, iconTarget) {
        const audioTarget = this.audioTargets.find(
            (el) => el.dataset.audioId === audioId,
        );
        if (audioTarget) {
            this.replaceChildrenWithTemplate(audioTarget, iconTarget);
        }
    }

    replaceChildrenWithTemplate(destEl, sourceEl) {
        destEl.replaceChildren(
            sourceEl.content.cloneNode(true).firstElementChild,
        );
    }

    get logRowCheckedInputs() {
        return this.logRowInputTargets.filter((el) => el.checked);
    }

    get drawerRowCheckedInputs() {
        return this.drawerRowInputTargets.filter((el) => el.checked);
    }
}

// Uncheck all checkedTargets, except for exceptTarget
function enforceSingleChecked(checkedTargets, exceptTarget) {
    if (checkedTargets.length > 1) {
        checkedTargets.forEach((el) => {
            if (el !== exceptTarget) {
                el.checked = false;
            }
        });
    }
}
